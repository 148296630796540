import { createGlobalStyle } from 'styled-components'
import 'typeface-space-mono'

export default createGlobalStyle`
  html {
    padding: 0;
    margin: 0;
    background-color:${(props) => props.theme.background};
  }

  ::selection {
      background-color:${(props) => props.theme.highlight};
      color: ${(props) => props.theme.background};
    }

  body {
    margin: 0;
    padding: 0
    background-color: ${(props) => props.theme.background};
    color: ${(props) => props.theme.main};
    font-family: 'Space Mono', 'Courier New', Courier, monospace;
    font-size: 16px;
  }

  h1 {
    font-size: 35px;
    line-height: 40px;
  }
  h2 {
    font-size: 22px;
    line-height: 28px;
    margin-top: 40px;
  }
  h3 {
    font-size: 18px;
    line-height: 22px;
  }

  ol {
    display: block;
    margin-bottom: 10px;
  }
`
