export const main = {
  background: '#07070E',
  main: 'white',
  highlight: 'white',
  inverted: '#ffcc15',
}

export const projects = {
  background: '#19007D',
  main: '#A9C6E9',
  highlight: '#A9C6E9',
  inverted: '#E82B2B',
}
export const projects2 = {
  background: '#5720F5',
  main: '#D1FF4D',
  highlight: '#D1FF4D',
  inverted: '#E6FF82',
}

export const youles = {
  background: '#3F17B1',
  main: 'white',
  highlight: '#03E9EB',
  inverted: '#FF1C1C',
}

export const sensie = {
  background: '#005251',
  main: 'white',
  highlight: '#00FFC2',
  inverted: '#FFBFBB',
}

export const queue = {
  background: '#0E0D0D',
  main: 'white',
  highlight: '#FF002E',
  inverted: '#FF002E',
}

export const sixtyThree = {
  background: '#130046',
  main: '#21FFD7',
  highlight: '#FF218C',
  inverted: '#FF00B8',
}

export const swift = {
  background: '#CD007B',
  main: '#E5FF47',
  highlight: '#E5FF47',
  inverted: '#FF1616',
}

export const plugins = {
  background: 'white',
  main: 'black',
  highlight: 'black',
  inverted: 'white',
}

export const qswimwear = {
  background: '#1C1F26',
  main: 'white',
  highlight: '#FFEB01',
  inverted: '#FFF500',
}

export const lineage = {
  background: '#07070E',
  main: 'white',
  highlight: '#E4E4E4',
  inverted: 'white',
}
