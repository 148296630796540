/* eslint-disable react/prop-types */
import React from 'react'
import PropTypes from 'prop-types'
import styled, { ThemeProvider } from 'styled-components'
import Global from './Global'
import NavBar from './NavBar'
import SEO from './seo'
import Pattern from '../images/pattern.png'
import device from '../lib/mediaQueries'

const LayoutContainer = styled.div`
  overflow: hidden;
  position: relative;
  background-color: ${(props) => props.theme.background};
  padding-top: 24px 0px;
`

const Square = styled.div`
  width: 40px;
  height: 40px;
  display: block;
  background-color: ${(props) => props.theme.highlight};
  position: absolute;
  top: 0;
  left: 0;
  z-index: 199;
  transform: none;
  transition: transform 0.2s ease-out;

  ${device.tablet} {
    position: fixed;
  }
`

const LinePattern = styled.div`
  position: fixed;
  display: block;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-image: url(${Pattern});
  background-size: calc(80px + 10vw);
  pointer-events: none;
  z-index: 200;
  opacity: 0.5;
`

const Layout = ({
  children,
  theme,
  absoluteTitle,
  style,
  title,
  description,
  image,
  squareHidden,
  patternHidden,
}) => (
  <ThemeProvider theme={theme}>
    <SEO
      absoluteTitle={absoluteTitle}
      title={title}
      description={description}
      image={image}
    />
    <Global />
    <LayoutContainer style={style}>
      {!squareHidden && <Square />}
      <NavBar />
      {children}
      {!patternHidden && <LinePattern />}
    </LayoutContainer>
  </ThemeProvider>
)

Layout.propTypes = {
  absoluteTitle: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  theme: PropTypes.shape.isRequired,
  squareHidden: PropTypes.bool,
  patternHidden: PropTypes.bool,
}

export default Layout
