import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import device from '../lib/mediaQueries'

const Nav = styled.nav`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 24px 10%;
  ${device.tablet} {
    padding: 24px 10%;
  }
`

const NavLink = styled(Link).attrs({
  activeStyle: {
    opacity: 1,
    fontWeight: 'bold',
  },
})`
  color: ${(props) => props.theme.highlight};
  text-decoration: none;
  margin-left: 16px;
  opacity: 0.4;
  transition: opacity 0.3s ease-out;

  &:hover {
    opacity: 1;
  }
`

const NavBar = ({ colors }) => (
  <Nav colors={colors}>
    <NavLink to="/">about</NavLink>
    <NavLink to="/work" partiallyActive>
      work
    </NavLink>
  </Nav>
)

NavBar.propTypes = {
  colors: PropTypes.shape.isRequired,
}

export default NavBar
